<template>
  <b-container fluid>
    <b-row>
      <b-col sm="12">
        <b-overlay :show="loading">
          <iq-card>
            <template v-slot:body>
              <ValidationObserver ref="form" v-slot="{ handleSubmit, reset }">
                <b-form @submit.prevent="handleSubmit(saveData)" @reset.prevent="reset" >
                  <b-row>
                      <b-col>
                          <h5 class="ex_form_title">{{ $t('admission_form.gen_inf') }}</h5>
                      </b-col>
                  </b-row>
                  <b-row class="mb-4">
                    <b-col sm="3">
                      <ValidationProvider name="Name (En)" vid="name" rules="required">
                        <b-form-group class="row" label-cols-sm="12" label-for="name" slot-scope="{ valid, errors }">
                          <template v-slot:label>
                            {{$t('common_config.farmer_name_en')}} <span class="text-danger">*</span>
                          </template>
                          <b-form-input
                              type="text"
                              id="name"
                              v-model="farmerInfo.name"
                              :state="errors[0] ? false : (valid ? true : null)"
                          ></b-form-input>
                          <div class="invalid-feedback">
                            {{ errors[0] }}
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>
                    <b-col sm="3">
                      <ValidationProvider name="Name (Bn)" vid="name_bn" rules="required">
                        <b-form-group class="row" label-cols-sm="12" label-for="name_bn" slot-scope="{ valid, errors }">
                          <template v-slot:label>
                            {{$t('common_config.farmer_name_bn')}} <span class="text-danger">*</span>
                          </template>
                          <b-form-input
                              type="text"
                              id="pay_grade"
                              v-model="farmerInfo.name_bn"
                              :state="errors[0] ? false : (valid ? true : null)"
                          ></b-form-input>
                          <div class="invalid-feedback">
                            {{ errors[0] }}
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>
                    <b-col sm="3">
                      <ValidationProvider name="Father Name (En)" vid="father_name" rules="required">
                        <b-form-group class="row" label-cols-sm="12" label-for="father_name" slot-scope="{ valid, errors }">
                          <template v-slot:label>
                            {{$t('common_config.farmer_father_name_en')}} <span class="text-danger">*</span>
                          </template>
                          <b-form-input
                              type="text"
                              id="pay_grade"
                              v-model="farmerInfo.father_name"
                              :state="errors[0] ? false : (valid ? true : null)"
                          ></b-form-input>
                          <div class="invalid-feedback">
                            {{ errors[0] }}
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>
                    <b-col sm="3">
                      <ValidationProvider name="Father Name (Bn)" vid="father_name_bn" rules="required">
                        <b-form-group class="row" label-cols-sm="12" label-for="father_name_bn" slot-scope="{ valid, errors }">
                          <template v-slot:label>
                            {{$t('common_config.farmer_father_name_bn')}} <span class="text-danger">*</span>
                          </template>
                          <b-form-input
                              v-model="farmerInfo.father_name_bn"
                              :state="errors[0] ? false : (valid ? true : null)"
                          >
                          </b-form-input>
                          <div class="invalid-feedback">
                            {{ errors[0] }}
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>
                    <b-col sm="3">
                      <ValidationProvider name="Mother Name (En)" vid="mother_name_en">
                        <b-form-group class="row" label-cols-sm="12" label-for="mother_name_en" slot-scope="{ valid, errors }">
                          <template v-slot:label>
                            {{$t('admission_form.mother_name_en')}}
                          </template>
                          <b-form-input
                              type="text"
                              id="mother_name_en"
                              v-model="farmerInfo.mother_name"
                              :state="errors[0] ? false : (valid ? true : null)"
                          ></b-form-input>
                          <div class="invalid-feedback">
                            {{ errors[0] }}
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>
                    <b-col sm="3">
                      <ValidationProvider name="Mother Name (Bn)" vid="mother_name_bn">
                        <b-form-group class="row" label-cols-sm="12" label-for="mother_name_bn" slot-scope="{ valid, errors }">
                          <template v-slot:label>
                            {{$t('admission_form.mother_name_bn')}}
                          </template>
                          <b-form-input
                              type="text"
                              id="pay_grade"
                              v-model="farmerInfo.mother_name_bn"
                              :state="errors[0] ? false : (valid ? true : null)"
                          ></b-form-input>
                          <div class="invalid-feedback">
                            {{ errors[0] }}
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>
                    <b-col sm="3">
                      <ValidationProvider name="Date of Birth" vid="date_of_birth">
                        <b-form-group class="row" label-cols-sm="12" label-for="date_of_birth" slot-scope="{ valid, errors }">
                          <template v-slot:label>
                            {{ $t('externalUserIrrigation.date_of_birth') }}
                          </template>
                          <b-form-input
                              class="date-picker"
                              v-model="farmerInfo.date_of_birth"
                              :placeholder="$t('globalTrans.select_date')"
                              :state="errors[0] ? false : (valid ? true : null)"
                          ></b-form-input>
                          <div class="invalid-feedback">
                            {{ errors[0] }}
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>
                    <b-col sm="3">
                      <ValidationProvider name="NID" vid="nid_no" rules="required">
                        <b-form-group class="row" label-cols-sm="12" label-for="nid" slot-scope="{ valid, errors }">
                          <template v-slot:label>
                            {{$t('admission_form.nid_no')}} <span class="text-danger">*</span>
                          </template>
                          <b-form-input
                              type="number"
                              id="nid_no"
                              v-model="farmerInfo.nid_no"
                              oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                              :state="errors[0] ? false : (valid ? true : null)"
                          ></b-form-input>
                          <div class="invalid-feedback">
                            {{ errors[0] }}
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>
                    <b-col sm="3">
                      <ValidationProvider name="Mobile No" vid="mobile_no" rules="required|digits:11">
                        <b-form-group class="row" label-cols-sm="12" label-for="mobile_no" slot-scope="{ valid, errors }">
                          <template v-slot:label>
                            {{$t('admission_form.mobile_no')}} <span class="text-danger">*</span>
                          </template>
                          <b-form-input
                              type="number"
                              id="mobile_no"
                              v-model="farmerInfo.mobile_no"
                              oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                              :state="errors[0] ? false : (valid ? true : null)"
                          ></b-form-input>
                          <div class="invalid-feedback">
                            {{ errors[0] }}
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>
                    <b-col sm="3">
                      <ValidationProvider name="Photo" vid="attachment" rules="required">
                        <b-form-group class="row" label-cols-sm="12" label-for="photo" slot-scope="{ valid, errors }">
                          <template v-slot:label>
                            {{ $t('admission_form.photo') }} <span class="text-danger">*</span>
                            <!-- <v-slot v-if="main_form.attachment !== null">
                              <a :href="baseUrl + 'download-attachment?file=uploads/general-information/photo/original/' + main_form.photo" title="Photo" class="mr-3"><i class="ri-download-cloud-fill"></i></a>
                            </v-slot> -->
                          </template>
                          <b-form-file
                              id="attachment"
                              v-model="farmerInfo.attachment"
                              @change="onFileChange"
                              :state="errors[0] ? false : (valid ? true : null)"
                          >
                          </b-form-file>
                          <div class="invalid-feedback">
                            {{ errors[0] }}
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col>
                      <h5 class="ex_form_title">{{ $t('admission_form.add_info') }}</h5>
                    </b-col>
                  </b-row>
                  <b-row class="mb-4">
                    <b-col sm="3">
                      <ValidationProvider name="Area Type" vid="area_type_id" rules="required|min_value:1">
                        <b-form-group
                            class="row"
                            label-cols-sm="12"
                            label-for="area_type_id "
                            slot-scope="{ valid, errors }"
                        >
                          <template v-slot:label>
                            {{$t('org_pro.area_type')}} <span class="text-danger">*</span>
                          </template>
                          <b-form-select
                              plain
                              v-model="farmerInfo.area_type_id"
                              id="area_type_id"
                              :options="areaTypeList"
                              @change="getAreaTypeData(farmerInfo.area_type_id)"
                              :state="errors[0] ? false : (valid ? true : null)"
                          >
                            <template v-slot:first>
                              <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                            </template>
                          </b-form-select>
                          <div class="invalid-feedback">
                            {{ errors[0] }}
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>
                    <b-col sm="3" v-show="ItemShow">
                      <ValidationProvider name="Division" vid="division_id" rules="required|min_value:1">
                        <b-form-group
                            class="row"
                            label-cols-sm="12"
                            label-for="division_id"
                            slot-scope="{ valid, errors }"
                        >
                          <template v-slot:label>
                            {{ $t('org_pro_division.division')}} <span class="text-danger">*</span>
                          </template>
                          <b-form-select
                              plain
                              v-model="farmerInfo.division_id"
                              :options="divisionList"
                              id="division_id"
                              :state="errors[0] ? false : (valid ? true : null)"
                          >
                            <template v-slot:first>
                              <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                            </template>
                          </b-form-select>
                          <div class="invalid-feedback">
                            {{ errors[0] }}
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>
                    <b-col sm="3" v-show="ItemShow">
                      <ValidationProvider name="District" vid="district_id" rules="required|min_value:1">
                        <b-form-group
                            class="row"
                            label-cols-sm="12"
                            label-for="district_id"
                            slot-scope="{ valid, errors }"
                        >
                          <template v-slot:label>
                            {{ $t('org_pro_district.district')}} <span class="text-danger">*</span>
                          </template>
                          <b-form-select
                              plain
                              v-model="farmerInfo.district_id"
                              :options="districtList"
                              id="district_id"
                              :state="errors[0] ? false : (valid ? true : null)"
                          >
                            <template v-slot:first>
                              <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                            </template>
                          </b-form-select>
                          <div class="invalid-feedback">
                            {{ errors[0] }}
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>
                    <b-col sm="3" v-show="PauroshobaItemShow || UnionItemShow">
                      <ValidationProvider name="Upazila" vid="upazila_id">
                        <b-form-group
                            class="row"
                            label-cols-sm="12"
                            label-for="upazila_id"
                            slot-scope="{ valid, errors }"
                        >
                          <template v-slot:label>
                            {{ $t('org_pro_upazilla.upazilla')}} <span class="text-danger">*</span>
                          </template>
                          <b-form-select
                              plain
                              v-model="farmerInfo.upazilla_id"
                              :options="upazilaList"
                              id="upazila_id"
                              :state="errors[0] ? false : (valid ? true : null)"
                          >
                            <template v-slot:first>
                              <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                            </template>
                          </b-form-select>
                          <div class="invalid-feedback">
                            {{ errors[0] }}
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>
                    <b-col sm="3" v-show="UnionItemShow">
                      <ValidationProvider name="Union" vid="union_id">
                        <b-form-group
                            class="row"
                            label-cols-sm="12"
                            label-for="union_id"
                            slot-scope="{ valid, errors }"
                        >
                          <template v-slot:label>
                            {{ $t('org_pro_union.union')}} <span class="text-danger">*</span>
                          </template>
                          <b-form-select
                              plain
                              v-model="farmerInfo.union_id"
                              :options="unionList"
                              id="union_id"
                              :state="errors[0] ? false : (valid ? true : null)"
                          >
                            <template v-slot:first>
                              <b-form-select-option :value=0>{{$t('globalTrans.select')}}</b-form-select-option>
                            </template>
                          </b-form-select>
                          <div class="invalid-feedback">
                            {{ errors[0] }}
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>
                    <b-col sm="3" v-show="CityCorpItemShow">
                      <ValidationProvider name="City Corporation" vid="city_corporation_id">
                        <b-form-group
                            class="row"
                            label-cols-sm="12"
                            label-for="city_corporation_id"
                            slot-scope="{ valid, errors }"
                        >
                          <template v-slot:label>
                            {{ $t('org_pro.city_corporation') }} <span class="text-danger">*</span>
                          </template>
                          <b-form-select
                              plain
                              v-model="farmerInfo.city_corporation_id"
                              :options="cityCorporationList"
                              id="city_corporation_id"
                              :state="errors[0] ? false : (valid ? true : null)"
                          >
                            <template v-slot:first>
                              <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                            </template>
                          </b-form-select>
                          <div class="invalid-feedback">
                            {{ errors[0] }}
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>
                    <b-col sm="3" v-show="PauroshobaItemShow">
                      <ValidationProvider name="Pauroshoba" vid="pauroshoba_id">
                        <b-form-group
                            class="row"
                            label-cols-sm="12"
                            label-for="pauroshoba_id"
                            slot-scope="{ valid, errors }"
                        >
                          <template v-slot:label>
                            {{ $t('org_pro.pauroshoba') }} <span class="text-danger">*</span>
                          </template>
                          <b-form-select
                              plain
                              v-model="farmerInfo.pauroshoba_id"
                              :options="pauroshobaList"
                              id="pauroshoba_id"
                              :state="errors[0] ? false : (valid ? true : null)"
                          >
                            <template v-slot:first>
                              <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                            </template>
                          </b-form-select>
                          <div class="invalid-feedback">
                            {{ errors[0] }}
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>
                    <b-col sm="3" v-show="ItemShow && CityCorpItemShow">
                      <ValidationProvider name="Ward" vid="ward_id">
                        <b-form-group
                            class="row"
                            label-cols-sm="12"
                            label-for="ward_id"
                            slot-scope="{ valid, errors }"
                        >
                          <template v-slot:label>
                            {{ $t('org_pro.ward') }}
                          </template>
                          <b-form-select
                              plain
                              v-model="farmerInfo.ward_id"
                              :options="wardList"
                              id="ward_id"
                              :state="errors[0] ? false : (valid ? true : null)"
                          >
                            <template v-slot:first>
                              <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                            </template>
                          </b-form-select>
                          <div class="invalid-feedback">
                            {{ errors[0] }}
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>
                    <b-col lg="4" sm="12" v-show="ItemShow">
                        <ValidationProvider name="Address" vid="address_en">
                            <b-form-group
                                class="row"
                                label-cols-sm="12"
                                label-for="address_en"
                                slot-scope="{ valid, errors }"
                                >
                                <template v-slot:label>
                                    {{ $t('farm_config.address') }} {{ ' ' + $t('globalTrans.enn') }}
                                </template>
                                <b-form-input
                                  type="text"
                                  id="address_en"
                                  v-model="farmerInfo.address_en"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                ></b-form-input>
                                <div class="invalid-feedback">
                                {{ errors[0] }}
                                </div>
                            </b-form-group>
                        </ValidationProvider>
                    </b-col>
                    <b-col lg="4" sm="12" v-show="ItemShow">
                      <ValidationProvider name="Address" vid="address_bn">
                        <b-form-group
                            class="row"
                            label-for="address_bn"
                            label-cols-sm="12"
                            slot-scope="{ valid, errors }"
                            >
                            <template v-slot:label>
                                {{ $t('farm_config.address') }} {{ ' ' + $t('globalTrans.bnn') }}
                            </template>
                            <b-form-input
                              type="text"
                              id="address_bn"
                              v-model="farmerInfo.address_bn"
                              :state="errors[0] ? false : (valid ? true : null)"
                            ></b-form-input>
                            <div class="invalid-feedback">
                            {{ errors[0] }}
                            </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col>
                      <h5 class="ex_form_title">{{ $t('educational_management.permanent_address') }}</h5>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col sm="6">
                      <ValidationProvider>
                        <b-form-group
                            class="row mb-0"
                            :label="$t('educational_management.present_business_address')"
                            label-cols-sm="3"
                            label-for="present_address"
                            slot-scope="{ errors }"
                        >
                          <div>
                            <b-form-checkbox
                                class="mt-1"
                                id="same_as_present_address"
                                v-model="same_as_present_address"
                                value="1"
                                unchecked-value="0"
                            >
                              {{$t('admission_form.same')}}
                            </b-form-checkbox>
                          </div>
                          <div class="invalid-feedback">
                            {{ errors[0] }}
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>
                  </b-row>
                  <b-row class="mb-4">
                    <b-col sm="3">
                      <ValidationProvider name="Area Type" vid="per_area_type_id" rules="required|min_value:1">
                        <b-form-group
                            class="row"
                            label-cols-sm="12"
                            label-for="per_area_type_id "
                            slot-scope="{ valid, errors }"
                        >
                          <template v-slot:label>
                            {{$t('org_pro.area_type')}} <span class="text-danger">*</span>
                          </template>
                          <b-form-select
                              plain
                              :disabled="same_as_present_address === 1"
                              v-model="farmerInfo.per_area_type_id"
                              id="per_area_type_id"
                              :options="areaTypeList"
                              @change="getAreaTypePerData(farmerInfo.per_area_type_id)"
                              :state="errors[0] ? false : (valid ? true : null)"
                          >
                            <template v-slot:first>
                              <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                            </template>
                          </b-form-select>
                          <div class="invalid-feedback">
                            {{ errors[0] }}
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>
                    <b-col sm="3" v-show="ItemShowPer">
                      <ValidationProvider name="Division" vid="per_division_id" rules="required|min_value:1">
                        <b-form-group
                            class="row"
                            label-cols-sm="12"
                            label-for="per_division_id"
                            slot-scope="{ valid, errors }"
                        >
                          <template v-slot:label>
                            {{ $t('org_pro_division.division')}} <span class="text-danger">*</span>
                          </template>
                          <b-form-select
                              plain
                              :disabled="same_as_present_address === 1"
                              v-model="farmerInfo.per_division_id"
                              :options="divisionPerList"
                              id="per_division_id"
                              :state="errors[0] ? false : (valid ? true : null)"
                          >
                            <template v-slot:first>
                              <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                            </template>
                          </b-form-select>
                          <div class="invalid-feedback">
                            {{ errors[0] }}
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>
                    <b-col sm="3" v-show="ItemShowPer">
                      <ValidationProvider name="District" vid="per_district_id" rules="required|min_value:1">
                        <b-form-group
                            class="row"
                            label-cols-sm="12"
                            label-for="per_district_id"
                            slot-scope="{ valid, errors }"
                        >
                          <template v-slot:label>
                            {{ $t('org_pro_district.district')}} <span class="text-danger">*</span>
                          </template>
                          <b-form-select
                              plain
                              :disabled="same_as_present_address === 1"
                              v-model="farmerInfo.per_district_id"
                              :options="districtPerList"
                              id="per_district_id"
                              :state="errors[0] ? false : (valid ? true : null)"
                          >
                            <template v-slot:first>
                              <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                            </template>
                          </b-form-select>
                          <div class="invalid-feedback">
                            {{ errors[0] }}
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>
                    <b-col sm="3" v-show="PauroshobaItemPerShow || UnionItemPerShow">
                      <ValidationProvider name="Upazila" vid="per_upazila_id">
                        <b-form-group
                            class="row"
                            label-cols-sm="12"
                            label-for="per_upazila_id"
                            slot-scope="{ valid, errors }"
                        >
                          <template v-slot:label>
                            {{ $t('org_pro_upazilla.upazilla')}} <span class="text-danger">*</span>
                          </template>
                          <b-form-select
                              plain
                              :disabled="same_as_present_address === 1"
                              v-model="farmerInfo.per_upazilla_id"
                              :options="upazilaPerList"
                              id="per_upazila_id"
                              :state="errors[0] ? false : (valid ? true : null)"
                          >
                            <template v-slot:first>
                              <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                            </template>
                          </b-form-select>
                          <div class="invalid-feedback">
                            {{ errors[0] }}
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>
                    <b-col sm="3" v-show="UnionItemPerShow">
                      <ValidationProvider name="Union" vid="per_union_id">
                        <b-form-group
                            class="row"
                            label-cols-sm="12"
                            label-for="per_union_id"
                            slot-scope="{ valid, errors }"
                        >
                          <template v-slot:label>
                            {{ $t('org_pro_union.union')}} <span class="text-danger">*</span>
                          </template>
                          <b-form-select
                              plain
                              :disabled="same_as_present_address === 1"
                              v-model="farmerInfo.per_union_id"
                              :options="unionPerList"
                              id="per_union_id"
                              :state="errors[0] ? false : (valid ? true : null)"
                          >
                            <template v-slot:first>
                              <b-form-select-option :value=0>{{$t('globalTrans.select')}}</b-form-select-option>
                            </template>
                          </b-form-select>
                          <div class="invalid-feedback">
                            {{ errors[0] }}
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>
                    <b-col sm="3" v-show="CityCorpItemPerShow">
                      <ValidationProvider name="City Corporation" vid="per_city_corporation_id">
                        <b-form-group
                            class="row"
                            label-cols-sm="12"
                            label-for="per_city_corporation_id"
                            slot-scope="{ valid, errors }"
                        >
                          <template v-slot:label>
                            {{ $t('org_pro.city_corporation') }} <span class="text-danger">*</span>
                          </template>
                          <b-form-select
                              plain
                              :disabled="same_as_present_address === 1"
                              v-model="farmerInfo.per_city_corporation_id"
                              :options="cityCorporationPerList"
                              id="per_city_corporation_id"
                              :state="errors[0] ? false : (valid ? true : null)"
                          >
                            <template v-slot:first>
                              <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                            </template>
                          </b-form-select>
                          <div class="invalid-feedback">
                            {{ errors[0] }}
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>
                    <b-col sm="3" v-show="PauroshobaItemPerShow">
                      <ValidationProvider name="Pauroshoba" vid="per_pauroshoba_id">
                        <b-form-group
                            class="row"
                            label-cols-sm="12"
                            label-for="per_pauroshoba_id"
                            slot-scope="{ valid, errors }"
                        >
                          <template v-slot:label>
                            {{ $t('org_pro.pauroshoba') }} <span class="text-danger">*</span>
                          </template>
                          <b-form-select
                              plain
                              :disabled="same_as_present_address === 1"
                              v-model="farmerInfo.per_pauroshoba_id"
                              :options="pauroshobaPerList"
                              id="per_pauroshoba_id"
                              :state="errors[0] ? false : (valid ? true : null)"
                          >
                            <template v-slot:first>
                              <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                            </template>
                          </b-form-select>
                          <div class="invalid-feedback">
                            {{ errors[0] }}
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>
                    <b-col sm="3" v-show="ItemShowPer && CityCorpItemPerShow">
                      <ValidationProvider name="Ward" vid="per_ward_id">
                        <b-form-group
                            class="row"
                            label-cols-sm="12"
                            label-for="per_ward_id"
                            slot-scope="{ valid, errors }"
                        >
                          <template v-slot:label>
                            {{ $t('org_pro.ward') }}
                          </template>
                          <b-form-select
                              plain
                              :disabled="same_as_present_address === 1"
                              v-model="farmerInfo.per_ward_id"
                              :options="wardPerList"
                              id="ward_id"
                              :state="errors[0] ? false : (valid ? true : null)"
                          >
                            <template v-slot:first>
                              <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                            </template>
                          </b-form-select>
                          <div class="invalid-feedback">
                            {{ errors[0] }}
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>
                    <b-col lg="4" sm="12" v-show="ItemShowPer">
                        <ValidationProvider name="address" vid="per_address_en">
                            <b-form-group
                                class="row"
                                label-cols-sm="12"
                                label-for="per_address_en"
                                slot-scope="{ valid, errors }"
                                >
                                <template v-slot:label>
                                    {{ $t('farm_config.address') }} {{ ' ' + $t('globalTrans.enn') }}
                                </template>
                                <b-form-input
                                  type="text"
                                  id="per_address_en"
                                  :disabled="same_as_present_address === 1"
                                  v-model="farmerInfo.per_address_en"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                ></b-form-input>
                                <div class="invalid-feedback">
                                {{ errors[0] }}
                                </div>
                            </b-form-group>
                        </ValidationProvider>
                    </b-col>
                    <b-col lg="4" sm="12" v-show="ItemShowPer">
                      <ValidationProvider name="Address" vid="per_address_bn">
                          <b-form-group
                              class="row"
                              label-cols-sm="12"
                              label-for="per_address_bn"
                              slot-scope="{ valid, errors }"
                              >
                              <template v-slot:label>
                                  {{ $t('farm_config.address') }} {{ ' ' + $t('globalTrans.bnn') }}
                              </template>
                              <b-form-input
                                type="text"
                                id="per_block_bn"
                                :disabled="same_as_present_address === 1"
                                v-model="farmerInfo.per_address_bn"
                                :state="errors[0] ? false : (valid ? true : null)"
                              ></b-form-input>
                              <div class="invalid-feedback">
                              {{ errors[0] }}
                              </div>
                          </b-form-group>
                      </ValidationProvider>
                    </b-col>
                  </b-row>
                  <div class="text-right m-3">
                    <b-button-group>
                      <b-button type="submit"
                        variant="success"
                        :disabled="farmerInfo.save_status === 2"
                        title="Final Save cannot be reverted!"
                        class="rounded">
                        <i class="fas fa-lock ml-1"></i> {{ $t('externalUserIrrigation.final_save') }}
                      </b-button>
                      <!-- <b-button type="submit"
                          variant="danger"
                          title="Final Save cannot be reverted!"
                          class="ml-1 rounded">{{ $t('externalUserIrrigation.final_save') }}
                          <i class="fas fa-lock ml-1"></i>
                      </b-button> -->
                    </b-button-group>
                  </div>
                </b-form>
              </ValidationObserver>
            </template>
          </iq-card>
        </b-overlay>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { incentiveGrantServiceBaseUrl } from '@/config/api_config'
import { profileInfoShow, profileInfoUpdate, profileInfoStore } from '../../api/routes'
import flatpickr from 'flatpickr'
import { EventBus } from '@/EventBusLayout'

export default {
  // props: ['id'],
  components: {
    ValidationObserver,
    ValidationProvider
  },
  data () {
    return {
      saveBtnName: this.$route.query.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
      // loading: false,
      showIdType: 0,
      ItemShow: false,
      inputFieldShow: false,
      ItemShowPer: false,
      CityCorpItemShow: false,
      CityCorpItemPerShow: false,
      PauroshobaItemShow: false,
      PauroshobaItemPerShow: false,
      UnionItemShow: false,
      UnionItemPerShow: false,
      same_as_present_address: false,
      same_as_mobile_number: false,
      districtList: [],
      upazilaList: [],
      unionList: [],
      cityCorporationList: [],
      pauroshobaList: [],
      wardList: [],
      blockList: [],
      blockPerList: [],
      districtPerList: [],
      upazilaPerList: [],
      unionPerList: [],
      cityCorporationPerList: [],
      pauroshobaPerList: [],
      wardPerList: [],
      millInfoList: [],
      subzoneOfficeList: [],
      centerList: [],
      unitList: [],
      farmerInfo: {
        id: 0,
        name: '',
        name_bn: '',
        father_name: '',
        father_name_bn: '',
        mother_name: '',
        mother_name_bn: '',
        mobile_no: '',
        nid_no: '',
        attachment: null,
        area_type_id: 0,
        city_corporation_id: 0,
        pauroshoba_id: 0,
        division_id: 0,
        district_id: 0,
        upazilla_id: 0,
        union_id: 0,
        address_en: null,
        address_bn: null,
        ward_id: 0,
        is_same: 0,
        per_area_type_id: 0,
        per_city_corporation_id: 0,
        per_pauroshoba_id: 0,
        per_division_id: 0,
        per_district_id: 0,
        per_upazilla_id: 0,
        per_union_id: 0,
        per_ward_id: 0
      }
    }
  },
  async created () {
    const tmp = await this.getFarmerInfoData()
    this.farmerInfo = tmp
    if (tmp.id === 0) {
      this.farmerInfo = Object.assign(tmp, this.commonProfile, { nid_no: this.commonProfile.nid, gender_id: this.commonProfile.gender }, { division_id: tmp.division_id, district_id: tmp.district_id })
    }
    if (this.farmerInfo.area_type_id) {
      this.getAreaTypeData(this.farmerInfo.area_type_id)
    }
    if (this.farmerInfo.per_area_type_id) {
      this.getAreaTypePerData(this.farmerInfo.per_area_type_id)
    }
    this.same_as_present_address = this.farmerInfo.is_same ? this.farmerInfo.is_same : false
  },
  mounted () {
    flatpickr('.date-picker', {})
    core.index()
  },
  watch: {
    'farmerInfo.division_id': function (newVal, oldVal) {
      this.districtList = this.getDistrictList(newVal)
    },
    'farmerInfo.per_division_id': function (newVal, oldVal) {
      this.districtPerList = this.getDistrictPerList(newVal)
    },
    'farmerInfo.district_id': function (newVal, oldVal) {
      this.cityCorporationList = this.getCityCorporationList(newVal)
      this.upazilaList = this.getUpazilaList(newVal)
    },
    'farmerInfo.per_district_id': function (newVal, oldVal) {
      this.cityCorporationPerList = this.getCityCorporationPerList(newVal)
      this.upazilaPerList = this.getUpazilaPerList(newVal)
    },
    'farmerInfo.upazilla_id': function (newVal, oldVal) {
      this.unionList = this.getUnionList(newVal)
      this.pauroshobaList = this.getPauroshobaList(newVal)
    },
    'farmerInfo.per_upazilla_id': function (newVal, oldVal) {
      this.unionPerList = this.getUnionPerList(newVal)
      this.pauroshobaPerList = this.getPauroshobaPerList(newVal)
    },
    'farmerInfo.city_corporation_id': function (newVal, oldVal) {
      this.wardList = this.getWardListByCityCorportaion(newVal)
    },
    'farmerInfo.per_city_corporation_id': function (newVal, oldVal) {
      if (newVal !== 0) {
        this.wardPerList = this.getWardListByCityCorportaionPer(newVal)
      }
    },
    'farmerInfo.union_id': function (newVal, oldVal) {
      this.wardList = this.getWardListByUnion(newVal)
    },
    'farmerInfo.per_union_id': function (newVal, oldVal) {
      if (parseInt(newVal) > 0) {
        this.wardPerList = this.getWardPerListByUnion(newVal)
      }
    },
    same_as_present_address: function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.farmerInfo.per_area_type_id = this.farmerInfo.area_type_id
        this.farmerInfo.per_city_corporation_id = this.farmerInfo.city_corporation_id
        this.farmerInfo.per_pauroshoba_id = this.farmerInfo.pauroshoba_id
        this.farmerInfo.per_division_id = this.farmerInfo.division_id
        this.farmerInfo.per_district_id = this.farmerInfo.district_id
        this.farmerInfo.per_upazilla_id = this.farmerInfo.upazilla_id
        this.farmerInfo.per_union_id = this.farmerInfo.union_id
        this.farmerInfo.per_ward_id = this.farmerInfo.ward_id
        this.farmerInfo.per_address_en = this.farmerInfo.address_en
        this.farmerInfo.per_address_bn = this.farmerInfo.address_bn
        this.farmerInfo.is_same = newVal
        this.ItemShowPer = true
        if (this.farmerInfo.per_area_type_id === 1) {
          this.CityCorpItemPerShow = true
          this.PauroshobaItemPerShow = false
          this.UnionItemPerShow = false
        } else if (this.farmerInfo.per_area_type_id === 2) {
          this.CityCorpItemPerShow = false
          this.PauroshobaItemPerShow = true
          this.UnionItemPerShow = false
        } else if (this.farmerInfo.per_area_type_id === 3) {
          this.CityCorpItemPerShow = false
          this.PauroshobaItemPerShow = false
          this.UnionItemPerShow = true
        }
      }
    }
  },
  computed: {
    divisionList: function () {
      return this.$store.state.ExternalUserIrrigation.commonObj.divisionList.filter(item => item.status === 0)
    },
    divisionPerList: function () {
      return this.$store.state.ExternalUserIrrigation.commonObj.divisionList.filter(item => item.status === 0)
    },
    areaTypeList: function () {
      const list = [
        { value: 1, text: this.$i18n.locale === 'bn' ? 'সিটি কর্পোরেশন' : 'City Corpoation' },
        { value: 2, text: this.$i18n.locale === 'bn' ? 'পৌরসভা' : 'Pauroshoba' },
        { value: 3, text: this.$i18n.locale === 'bn' ? 'ইউনিয়ন' : 'Union' }
      ]
      return list
    },
    loading () {
      return this.$store.state.commonObj.loading
    }
  },
  methods: {
    getAreaTypeData (typeId) {
      this.ItemShow = true
      if (typeId === 1) {
        this.CityCorpItemShow = true
        this.PauroshobaItemShow = false
        this.UnionItemShow = false
      } else if (typeId === 2) {
        this.CityCorpItemShow = false
        this.PauroshobaItemShow = true
        this.UnionItemShow = false
      } else if (typeId === 3) {
        this.CityCorpItemShow = false
        this.PauroshobaItemShow = false
        this.UnionItemShow = true
      }
    },
    getAreaTypePerData (typeId2) {
      this.ItemShowPer = true
      if (typeId2 === 1) {
        this.CityCorpItemPerShow = true
        this.PauroshobaItemPerShow = false
        this.UnionItemPerShow = false
      } else if (typeId2 === 2) {
        this.CityCorpItemPerShow = false
        this.PauroshobaItemPerShow = true
        this.UnionItemPerShow = false
      } else if (typeId2 === 3) {
        this.CityCorpItemPerShow = false
        this.PauroshobaItemPerShow = false
        this.UnionItemPerShow = true
      }
    },
    getDistrictList (divisionId = null) {
      const districtList = this.$store.state.ExternalUserIrrigation.commonObj.districtList.filter(item => item.status === 0)
      if (divisionId) {
        return districtList.filter(district => district.division_id === divisionId)
      }
      return districtList
    },
    getDistrictPerList (divisionPerId = null) {
      const districtList = this.$store.state.ExternalUserIrrigation.commonObj.districtList.filter(item => item.status === 0)

      if (divisionPerId) {
        return districtList.filter(district => district.division_id === divisionPerId)
      }

      return districtList
    },
    getUpazilaList (districtId = null) {
      const upazilaList = this.$store.state.ExternalUserIrrigation.commonObj.upazilaList.filter(item => item.status === 0)

      if (districtId) {
        return upazilaList.filter(upazila => upazila.district_id === districtId)
      }

      return upazilaList
    },
    getUpazilaPerList (districtId = null) {
      const upazilaList = this.$store.state.ExternalUserIrrigation.commonObj.upazilaList.filter(item => item.status === 0)
      if (districtId) {
        return upazilaList.filter(upazila => upazila.district_id === districtId)
      }
      return upazilaList
    },
    getUnionList (upazilaId = null) {
      const unionList = this.$store.state.ExternalUserIrrigation.commonObj.unionList.filter(item => item.status === 0)
      if (upazilaId) {
        return unionList.filter(union => union.upazilla_id === upazilaId)
      }

      return unionList
    },
    getUnionPerList (upazilaId = null) {
      const unionList = this.$store.state.ExternalUserIrrigation.commonObj.unionList.filter(item => item.status === 0)
      if (upazilaId) {
        return unionList.filter(union => union.upazilla_id === upazilaId)
      }

      return unionList
    },
    getCityCorporationList (districtId) {
      const objectData = this.$store.state.ExternalIncentiveFarmer.commonObjCommonConfig.cityCorporationList.filter(item => item.status === 0 && item.district_id === districtId)
      return objectData.map((obj, key) => {
        if (this.$i18n.locale === 'bn') {
          return { value: obj.value, text: obj.text_bn }
        } else {
          return { value: obj.value, text: obj.text_en }
        }
      })
    },
    getCityCorporationPerList (districtId) {
      const objectData = this.$store.state.ExternalIncentiveFarmer.commonObjCommonConfig.cityCorporationList.filter(item => item.status === 0 && item.district_id === districtId)
      return objectData.map((obj, key) => {
        if (this.$i18n.locale === 'bn') {
          return { value: obj.value, text: obj.text_bn }
        } else {
          return { value: obj.value, text: obj.text_en }
        }
      })
    },
    getPauroshobaList (upazillaId = null) {
      const objectData = this.$store.state.ExternalIncentiveFarmer.commonObjCommonConfig.pauroshobaList.filter(item => item.upazilla_id === upazillaId)
      if (upazillaId) {
        return objectData.map((obj, key) => {
          if (this.$i18n.locale === 'bn') {
            return { value: obj.value, text: obj.text_bn }
          } else {
            return { value: obj.value, text: obj.text_en }
          }
        })
      }
    },
    getPauroshobaPerList (upazillaId = null) {
      const objectData = this.$store.state.ExternalIncentiveFarmer.commonObjCommonConfig.pauroshobaList.filter(item => item.upazilla_id === upazillaId)
      if (upazillaId) {
        return objectData.map((obj, key) => {
          if (this.$i18n.locale === 'bn') {
            return { value: obj.value, text: obj.text_bn }
          } else {
            return { value: obj.value, text: obj.text_en }
          }
        })
      }
    },
    getWardListByCityCorportaion (cityCorpId) {
      const objectData = this.$store.state.ExternalIncentiveFarmer.commonObjCommonConfig.wardList
      const wardObjectList = objectData.filter(item => item.city_corporation_id === cityCorpId && item.status === 0)
      return wardObjectList.map((obj, key) => {
        if (this.$i18n.locale === 'bn') {
          return { value: obj.value, text: obj.text_bn }
        } else {
          return { value: obj.value, text: obj.text }
        }
      })
    },
    getWardListByCityCorportaionPer (cityCorpId) {
      const objectData = this.$store.state.ExternalIncentiveFarmer.commonObjCommonConfig.wardList
      const wardObjectList = objectData.filter(item => item.city_corporation_id === cityCorpId && item.status === 0)
      return wardObjectList.map((obj, key) => {
        if (this.$i18n.locale === 'bn') {
          return { value: obj.value, text: obj.text_bn }
        } else {
          return { value: obj.value, text: obj.text }
        }
      })
    },
    getWardListByPauroshoba (pauroshobaId) {
      const objectData = this.$store.state.ExternalIncentiveFarmer.commonObjCommonConfig.wardList
      const wardObjectList = objectData.filter(item => item.pauroshoba_id === pauroshobaId && item.status === 0)
      return wardObjectList.map((obj, key) => {
        if (this.$i18n.locale === 'bn') {
          return { value: obj.value, text: obj.text_bn }
        } else {
          return { value: obj.value, text: obj.text }
        }
      })
    },
    getWardListByUnion (unionId) {
      const objectData = this.$store.state.ExternalIncentiveFarmer.commonObjCommonConfig.wardList
      const wardObjectList = objectData.filter(item => item.union_id === unionId && item.status === 0)
      return wardObjectList.map((obj, key) => {
        if (this.$i18n.locale === 'bn') {
          return { value: obj.value, text: obj.text_bn }
        } else {
          return { value: obj.value, text: obj.text }
        }
      })
    },
    getWardPerListByUnion (unionPerId) {
      const objectData = this.$store.state.ExternalIncentiveFarmer.commonObjCommonConfig.wardList
      const wardObjectList = objectData.filter(item => item.union_id === unionPerId && item.status === 0)
      return wardObjectList.map((obj, key) => {
        if (this.$i18n.locale === 'bn') {
          return { value: obj.value, text: obj.text_bn }
        } else {
          return { value: obj.value, text: obj.text }
        }
      })
    },
    async getFarmerInfoData () {
      this.$store.dispatch('mutateCommonProperties', { loading: true })
      const result = await RestApi.getData(incentiveGrantServiceBaseUrl, profileInfoShow)
      const tmpData = result.success ? result.data : this.farmerInfo
      this.$store.dispatch('mutateCommonProperties', { loading: false })
      return tmpData
      // return JSON.parse(JSON.stringify(tmpData))
    },
    onFileChange (e) {
      this.attachment = e.target.files[0]
    },
    async saveData () {
      if (this.farmerInfo.save_status === 2) {
        return
      }
      this.$store.dispatch('mutateCommonProperties', { loading: true })
      let result = null
      const loadingState = { loading: false, listReload: false }
      var formData = new FormData()
      Object.keys(this.farmerInfo).map(key => {
        if (key === 'attachment') {
          formData.append(key, this.attachment)
        } else {
          formData.append(key, this.farmerInfo[key])
        }
      })
      let uri = `${profileInfoStore}`
      if (this.farmerInfo.id !== 0) {
        uri = `${profileInfoUpdate}`
        formData.append('_method', 'PUT')
      }
      result = await RestApi.postData(incentiveGrantServiceBaseUrl, uri, formData)

      loadingState.listReload = true
      this.$store.dispatch('mutateCommonProperties', loadingState)

      if (result.success) {
        this.$store.commit('mutateIncentiveGrantServiceProperties', { hasDropdownLoaded: false })
        this.$toast.success({
          title: this.$t('globalTrans.success'),
          message: this.id ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
          color: '#D6E09B'
        })
        // this.farmerInfo.save_status = 2
        // this.$root.$refs.ExternalUserLayout.showIncentiveFarmerMenu()
        EventBus.$emit('INCENTIVE_FARMER_MENU_UPDATE')
        this.$router.push({ path: '/incentive-farmer/circular-list' })
        // location.reload()
      } else {
        if (result.errors) {
          this.$refs.form.setErrors(result.errors)
          this.$toast.error({
            title: 'Error',
            message: result.message,
            color: '#D6E09B'
          })
        } else {
          this.$toast.error({
            title: 'Error',
            message: result.message,
            color: '#D6E09B'
          })
        }
      }
      this.$nextTick(() => {
        this.$refs.form.reset()
      })
    }
  }
}
</script>
