
export const profileInfoBaseUrl = '/external-user/profile-info/'
export const profileInfoShow = profileInfoBaseUrl + 'show'
export const profileInfoUpdate = profileInfoBaseUrl + 'update'
export const profileInfoStore = profileInfoBaseUrl + 'store'
const application = 'external-user/application/'
export const circInstrList = application + 'circular-instruments'
export const applocationList = application + 'list'
export const applicationUpdate = application + 'update'
export const applicationStatus = application + 'toggle-status'
export const applicationStore = application + 'store'
export const applicationShow = application + 'show'

export const circularList = 'external-user/application/circular-list'
